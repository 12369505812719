.BookGrid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.BookCard {
		@media (max-width: 500px) {
			margin: 12px;
			width: calc(50% - 24px);
		}

		@media (min-width: 500px) {
			margin: 20px;
			width: 200px;
		}

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.BookCardCover {
			box-shadow: 3px 3px 12px lightgray;
			margin-bottom: 16px;
			position: relative;
			width: 100%;
		}

		.BookCardTitle,
		.BookCardSubtitle {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 4px;
		}

		.BookCardSubtitle {
			font-size: 80%;
			color: gray;
		}
	}

	.BookCard.Cached {
		.CachedIcon {
			position: absolute;
			top: auto;
			left: auto;
			bottom: 0;
			right: 0;
			width: 20%;
			height: 20%;

			color: green;
		}
	}
}
