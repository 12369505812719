.BookGrant {
	.BookTitle {
		margin-top: 0;
	}

	.BookSubtitle {
		margin-bottom: 0;
	}

	.BookCoverShadow {
		box-shadow: 3px 3px 12px lightgray;

		@media (max-width: 500px) {
			width: 60%;
		}

		@media (min-width: 500px) {
			width: 300px;
		}

		margin-bottom: 15px;
	}
}
