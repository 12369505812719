//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution
/// @example
///   h1 {
///     @include responsive-type(20px, 48px);
///   }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {
	font-size: calc(
		#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} *
			(
				(100vw - #{$lower-range}) / #{(
						($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1))
					)}
			)
	);

	@media screen and (max-width: $lower-range) {
		font-size: $min-font-size;
	}
	@media screen and (min-width: $upper-range) {
		font-size: $max-font-size;
	}
}

//Ionic breakpoints: more info: https://ionicframework.com/docs/layout/css-utilities#ionic-breakpoints
$size-xs: 360;
$size-sm: 576;
$size-md: 768;
$size-lg: 992;
$size-xl: 1200;

ion-toggle {
	--background-checked: var(--ion-color-pen-orange-tint);
}

ion-skeleton-text {
	margin-top: 0;
	margin-bottom: 0;
}

ion-toolbar {
	--background: transparent;
}

ion-tab-bar {
	background: linear-gradient(90.89deg, #ff9d42 -2.08%, #ea7000 98%);
	--background: transparent;
}

ion-tab-button {
	--color: #ffffff;
	--color-selected: #ffffff;
}

.tab-selected {
	background: rgba(255, 255, 255, 0.19);
	border-radius: 20px;
}

.hidden-overflow {
	--overflow: hidden;
}

.pd-debug {
	border: 1px solid;
	border-color: lawngreen;
}
.pd-debug-red {
	@extend .pd-debug;
	border-color: red;
}

.pd-tutorial-slide ion-img {
	pointer-events: none;
}
.pd-tutorial-img {
	display: flex;
	justify-content: center;
	ion-img {
		@media (orientation: landscape) {
			@media (max-width: $size-md + 'px') {
				max-width: 25%;
			}
		}
	}
}

.pd-tutorial-skip {
	padding-bottom: 50px;
	padding-top: 25px;
}

.splash-img {
	justify-content: center;
	align-items: center;
	align-self: center;
	height: 100%;
}

.underline {
	text-decoration: underline;
}

.pd-header {
	--background: transparent;
	background: linear-gradient(180deg, #ff9d42 0%, #ea7000 100%);
}

.pd-title {
	font-weight: bold;
	font-family: sans-serif;
	font-style: normal;
	line-height: 108.1%;
	text-align: center;
	color: #282828;

	@include fluid-type(16px, 24px);
}

ion-button {
	font-family: Roboto-Regular, sans-serif;
	font-style: normal;
	--color: #f7f7fc;
	--box-shadow: none;
	text-transform: none;
	width: auto;
	--background: var(--ion-color-primary);

	@media (max-width: 500px) {
		height: 40px;
		font-size: 16px;
	}

	@media (min-width: 500px) {
		height: 48px;
		font-size: 20px;
	}
}

ion-searchbar.pd-searchbar {
	--border-radius: 16px;
	--background: var(--ion-color-pen-white);
	padding: 0;
}

.searchbar-clear-icon {
	display: none !important;
}

.pd-back-button {
	--background: transparent;
}
.pd-back-button-contrast {
	--background: transparent;
	--color: #000000;
}

.pd-button-primary {
	--background: var(--ion-color-primary);
	--border-radius: 40px;
	min-width: 50%; // xs size
	@media (min-width: $size-sm + 'px') {
		min-width: 35%;
		@media (orientation: portrait) {
			min-width: 35%;
		}
	}
	@media (min-width: $size-md + 'px') {
		min-width: 30%;
		@media (orientation: portrait) {
			min-width: 30%;
		}
	}
	@media (min-width: $size-lg + 'px') {
		min-width: 25%;
	}
	@media (min-width: $size-xl + 'px') {
		min-width: 20%;
	}
}

.pd-button-primary-large {
	@extend .pd-button-primary;
	width: 90%;
	@media (min-width: $size-sm + 'px') {
		width: 60%;
	}
	@media (min-width: $size-md + 'px') {
		width: 50%;
	}
	@media (min-width: $size-lg + 'px') {
		width: 30%;
	}
	@media (min-width: $size-xl + 'px') {
		width: 20%;
	}
}

.pd-button-outline {
	@extend .pd-button-primary;
	--background: transparent var(--ion-color-primary-contrast);
	border: 2px solid var(--ion-color-primary);
	border-radius: 40px;
	color: var(--ion-color-primary);
}
.pd-button-outline-large {
	@extend .pd-button-outline;
	@extend .pd-button-primary-large;
}

.pd-control-page-button {
	@include fluid-type(12px, 16px);
}
.pd-color-button {
	@media (max-width: 500px) {
		height: 30px;
		width: 30px;
	}

	@media (min-width: 500px) {
		height: 40px;
		width: 40px;
	}
}

.pd-add-book-button ion-icon {
	color: #96b4db;
}
.pd-add-book-button {
	@include fluid-type(16px, 20px);

	color: #0075b5;
	font-style: normal;
	font-weight: bold;
	line-height: 23px;
	/* or 125% */
	letter-spacing: -0.270121px;

	border: 2px solid #f3d6b9;
	background: #fff0e0;
	border-radius: 11.2551px;

	padding: 10px;

	@media (orientation: landscape) {
		@media (max-width: $size-lg + 'px') {
			max-width: 18vw;
			max-height: 18vw;
		}
		@media (min-width: $size-lg + 'px') {
			max-width: 12vw;
			max-height: 12vw;
		}

		@media (min-width: $size-xl + 'px') {
			max-width: 10vw;
			max-height: 10vw;
		}

		@media (min-width: $size-xl + 500 + 'px') {
			max-width: 8vw;
			max-height: 8vw;
		}
	}

	@media (orientation: portrait) {
		@media (min-width: $size-xs + 'px') {
			max-width: 30vw;
			max-height: 30vw;
		}

		@media (min-width: $size-sm + 'px') {
			max-width: 25vw;
			max-height: 25vw;
		}

		@media (min-width: $size-md + 'px') {
			max-width: 20vw;
			max-height: 20vw;
		}
	}
}

.pd-header-title {
	font-style: normal;
	font-weight: bold;
	line-height: 120%;
	letter-spacing: 1px;
	color: #ffffff;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	@include fluid-type(15px, 26px);
}

.pd-header-category-title {
	@extend .pd-header-title;
	@include fluid-type(18px, 26px);
}

.pd-header-title-contrast {
	@extend .pd-header-title;
	color: #000000;
}

.pd-header-subtitle {
	font-weight: normal;
	letter-spacing: 0.75px;
	color: #ffffff;

	@media (max-width: 500px) {
		font-size: 14px;
	}

	@media (min-width: 500px) {
		font-size: 18px;
	}
}

.pd-header-subtitle-contrast {
	@extend .pd-header-subtitle;
	color: #000000;
}

ion-card.pd-category-card {
	margin: 0;
	padding: 10px;
	--color: #ffffff;
	border-radius: 11px;
	min-height: 150px;
	font-style: normal;
	font-weight: bold;
	line-height: 23px;
	max-height: 150px;

	img.card-img {
		position: absolute;
		max-height: 70%;
		bottom: 0;
		right: 0;
		@media (max-width: $size-xs + 'px') {
			max-height: 60%;
		}
	}

	/*TODO: it should be ion-img but it doesn't work at max height */
	ion-img.card-img {
		position: absolute;
		height: 80%;
		max-width: 40%;
		bottom: 0;
		right: 0;
	}
	.card-main-title {
		@include fluid-type(18px, 22px);
		color: #f7f7fc;
		font-style: normal;
		font-weight: bold;
		//padding-left: 10px;
		line-height: 23px;
		letter-spacing: -0.270121px;
	}
	.card-main-subtitle {
		@include fluid-type(14px, 18px);
		min-height: 60px;
		max-height: 60px;
		max-width: 65%;
		padding-top: 10%;
		//padding-left: 10px;
		font-style: normal;
		font-weight: bold;
		line-height: 23px;
		letter-spacing: -0.270121px;
		color: rgba(0, 0, 0, 0.3);
	}
}

.pd-book-list-thumbnail {
	height: 100%;
	@media (min-width: $size-xs + 'px') {
		width: 15%;
	}
	@media (min-width: $size-sm + 'px') {
		width: 15%;
	}
	@media (min-width: $size-md + 'px') {
		width: 20%;
	}
	@media (min-width: $size-lg + 'px') {
		width: 20%;
	}
	@media (min-width: $size-xl + 'px') {
		width: 20%;
	}
}

.pd-category-book-title {
	font-style: normal;
	font-weight: bold;
	@include fluid-type(15px, 19px);
	line-height: 20px;
	letter-spacing: -0.24px;
	color: #333333;
}

.pd-category-book-subtitle {
	font-style: normal;
	font-weight: 500;
	@include fluid-type(14px, 18px);
	line-height: 16px;
	color: #a6a6a6;
	padding-top: 4px;
}

.pd-book-detail-subtitle {
	@extend .pd-category-book-subtitle;
	@include fluid-type(15px, 19px);
}

.pd-category-avatar {
	position: absolute;
	z-index: inherit;
	right: 0;
	max-width: 70px;
	@media (min-width: $size-lg + 'px') {
		bottom: 0;
		max-width: 12%;
	}
}

.pd-main-page-title {
	font-style: normal;
	font-weight: 800;
	@include fluid-type(20px, 28px);
	line-height: 120%;
	color: #333333;
}

.pd-searchbar-avatar {
	position: absolute;
	max-width: 95px;
	bottom: 0;
	top: 0;
	right: 0;

	@media (max-width: $size-sm + 'px') {
		max-width: 20%;
		top: 20%;
	}
}

.pd-header-wave {
	position: absolute;
	z-index: inherit;
	top: 59px;
	height: 60px;
	background-color: transparent;
	width: 100%;

	@media (min-width: 500px) {
		top: 67px;
		height: 68px;
	}
}

.pd-category-page-title {
	font-style: normal;
	font-weight: 800;
	@include fluid-type(20px, 24px);
	line-height: 120%;
	color: #fff;
}

.pd-book-cover-shadow {
	box-shadow: 3px 3px 12px lightgray;
}

.pd-page-title-icon {
	@include fluid-type(36px, 100px);
}

.pd-book-acquisitions-card {
	border: dashed rgba(0, 0, 0, 0.2);
	border-width: 1px 0;
	margin: 0 5%;
}

.pd-welcome-title {
	font-style: normal;
	font-weight: 500;
	@include fluid-type(24px, 28px);
	line-height: 28px;
	letter-spacing: -0.1px;
	color: #121520;
}
.pd-welcome-text {
	/* Text/Normal */
	font-style: normal;
	font-weight: 500;
	@include fluid-type(16px, 24px);
	line-height: 116%;
	letter-spacing: 0.75px;
	color: #a6a6a6;
}
.pd-welcome-img {
	display: flex;
	justify-content: center;
	ion-img {
		@media (orientation: landscape) {
			@media (max-width: $size-md + 'px') {
				max-width: 25%;
			}
		}
	}
}

.pd-category-selected-book:hover {
	cursor: pointer;
	transition: transform 200ms;
	transform: translate(1%, 0);
}

.pd-privacy-policies {
	padding-right: 5px;
	@include fluid-type(14px, 18px);
}

.swiper-slide ion-grid {
	transform: scale(0.8);
}

.swiper-slide-active img {
	border: 2px solid var(--ion-color-primary);
}

.swiper-slide-active ion-grid {
	transform: scale(1);
	transition: transform 500ms;
}

.pd-book-page-popover {
	position: absolute;
	background-color: whitesmoke;
	width: 100%;
	border-radius: 40px 40px 0 0;
	z-index: inherit;
	bottom: 0;
	transition: all 200ms ease-out 0s;
}

.pd-book-page-popover-hide {
	transform: translateY(100%);
	transition: transform 200ms;
}

.pd-book-page-popover-selected {
	transition: transform 200ms;
	transform: scale(1.1);
}

.pd-book-page-popover-selected img {
	border: 2px solid var(--ion-color-primary);
}

.pd-book-page-popover-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.pd-book-page-popover-slide-mode {
	max-height: 60vh;
	@media (orientation: landscape) {
		max-height: 45vh;
	}
}
.pd-book-page-popover-grid-mode {
	overflow-x: hidden;
	max-height: 60vh;
	@media (orientation: landscape) {
		max-height: 45vh;
	}
}

.pd-book-page-popover-number-page {
	padding-top: 5px;
	@include fluid-type(10px, 14px);
}

.pd-debug-col-size {
	@media (min-width: $size-xs + 'px') {
		background: red;
	}
	@media (min-width: $size-sm + 'px') {
		background: blue;
	}
	@media (min-width: $size-md + 'px') {
		background: green;
	}
	@media (min-width: $size-lg + 'px') {
		background: brown;
	}
	@media (min-width: $size-xl + 'px') {
		background: yellow;
	}
}

.pd-icon-password {
	height: 100%;
	font-size: 15px;
}

.pd-form-input {
	display: flex;
	align-items: center;
	width: 100%;
}

.pd-button-default {
	@extend .pd-button-primary-large;
	--background: var(--ion-color-tertiary);
}

.scan-box {
	border: 2px solid #fff;
	box-shadow: 0 0 0 100vmax rgb(0, 0, 0, 0.5);
	content: '';
	display: block;
	left: 50%;
	height: 300px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 300px;
}

.pd-acquisition-card-title {
	background: #FDF6E7;
}